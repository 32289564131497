<script>

const url = "https://app.handelbay.com.co"
const sign_up = url + "/users/sign_up"
const sign_in = url + "/users/sign_in"
const search = 'https://buscador.handelbay.com'
const lp = "https://lp.handelbay.com/quiero-que-me-llamen"
const blog = url +"/blog"
const cellphone = "3147711318"
const soporte = "https://handelbay.freshdesk.com/support/solutions"
const facebook = "https://www.facebook.com/HandelBay/"
const instagram = "https://www.instagram.com/handel_bay/"
const youtube = "https://www.youtube.com/channel/UCOgFSPWHsAIiF_lTzUvr3MQ"
const linkedin = "https://www.linkedin.com/company/10471671/"
const indicative = "57" // colombia
const name_country = "Colombia"
const was_cellphone = "https://api.whatsapp.com/send/?phone=573147711318&text=Hola,+requiero+ayuda+en+la+plataforma+HandelBay&app_absent=0"

export default {
  url,
  sign_up,
  sign_in,
  search,
  lp,
  blog,
  cellphone,
  soporte,
  facebook,
  instagram,
  youtube,
  linkedin,
  indicative,
  name_country,
  was_cellphone
}
</script>
