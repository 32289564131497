<template>
  <div class="benefits_wrap">
    <div class="benefits_items">
      <div class="benefits_item">
        <div class="benefits_header">
          <img src="../assets/images/landing/handelbay/recursos_handel-56.png">
        </div>
        <div class="benefits_body">
          <div class="benefits_body-title">
            <h3 class="title">
              {{ $t('lang.benefits.get_quotes') }}
            </h3>
          </div>
          <div class="benefits_body-description">
            <p>{{ $t('lang.benefits.post_needs') }}</p>
          </div>
        </div>
        <div class="benefits_footer">
          <router-link to="/compradores" class="btn benefits_footer-action">
            {{ $t('lang.benefits.see_more') }}
          </router-link>
        </div>
      </div>
      <div class="benefits_item">
        <div class="benefits_header">
          <img src="../assets/images/landing/handelbay/recursos_handel-54.png">
        </div>
        <div class="benefits_body">
          <div class="benefits_body-title">
            <h3 class="title">
              {{ $t('lang.benefits.increase_your_sales') }}
            </h3>
          </div>
          <div class="benefits_body-description">
            <p>{{ $t('lang.benefits.improve_your_visibility') }}</p>
          </div>
        </div>
        <div class="benefits_footer">
          <router-link to="/compradores" class="btn benefits_footer-action">
            {{ $t('lang.benefits.see_more') }}
          </router-link>
        </div>
      </div>
      <div class="benefits_item">
        <div class="benefits_header">
          <img src="../assets/images/landing/handelbay/recursos_handel-55.png">
        </div>
        <div class="benefits_body">
          <div class="benefits_body-title">
            <h3 class="title">
              {{ $t('lang.benefits.improve_your_processes') }}
            </h3>
          </div>
          <div class="benefits_body-description">
            <p>{{ $t('lang.benefits.get_traceability') }}</p>
          </div>
        </div>
        <div class="benefits_footer">
          <router-link to="/compradores" class="btn benefits_footer-action">
            {{ $t('lang.benefits.see_more') }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
}
</script>