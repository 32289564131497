<template>
  <section id="because-handelbay" class="home-not-background">
    <div class="container">
      <div class="row">
        <div class="col-md-3 col-sm-12 col-xs-12 col-md-offset-1 text-left">
          <div class="title" itemscope :itemtype="global.url">
            <h2 class="title-2" itemprop="HandelBay en tu día a día">
              {{ $t('lang.why_handelbay.¿why') }}
              <br>
              {{ $t('lang.why_handelbay.HandelBay?') }}
            </h2>
            <span class="handelbay-active"></span>
          </div>
          <div class="handelbay-text" itemscope :itemtype="global.url">
            <p itemprop="HandelBay es la solución">
              {{ $t('lang.why_handelbay.we_want_to_transform_the_future_of_business_purchases_and_we_are_convinced_that_it_depends_on_3_fundamental_pillars') }}
            </p>
          </div>
        </div>
        <div class="col-md-7 col-sm-12 col-xs-12">
          <div class="row">
            <div class="col-md-4 col-sm-4 col-xs-6">
              <div class="image-1">
                <span>1</span>
                <a href="javascript:void(0)">
                  <img src="../assets/images/landing/handelbay/handelbay_porque_1.png" alt="Agilidad">
                  <div class="handelbay-effect-hover" itemscope :itemtype="global.url" style="display: none;">
                    <div class="because-handelbay_text">
                      <h3 class="title" itemprop="futuro">
                        {{ $t('lang.why_handelbay.agility') }}
                      </h3>
                      <p>
                        {{ $t('lang.why_handelbay.reduces_the_operating_time_of_the_shopping_area_by_70%') }} 
                        {{ $t('lang.why_handelbay.optimizes_commercial_management_by_80%') }}
                      </p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
              <div class="col-md-4 col-sm-4 col-xs-6">
                <div class="image-1">
                  <span>2</span>
                  <a href="javascript:void(0)">
                    <img src="../assets/images/landing/handelbay/handelbay_porque_2.png" alt="Agilidad">
                    <div class="handelbay-effect-hover" itemscope itemtype="https://handelbay.com/" style="display: none;">
                      <div class="because-handelbay_text">
                        <h3 class="title" itemprop="transformar">
                          {{ $t('lang.why_handelbay.transparency') }}
                        </h3>
                        <p>
                          {{ $t('lang.why_handelbay.work_collaboratively_and_keep_the_traceability_of_your_processes_to_facilitate_decision_making') }}
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-6">
                <div class="image-1">
                  <a href="javascript:void(0)">
                    <span>3</span>
                    <img src="../assets/images/landing/handelbay/handelbay_porque_3.png" alt="Agilidad">
                    <div class="handelbay-effect-hover" itemscope itemtype="https://handelbay.com/" style="display: none;">
                      <div class="because-handelbay_text">
                        <h3 class="title" itemprop="empresariales">
                          {{ $t('lang.why_handelbay.security') }}
                        </h3>
                        <p>
                          {{ $t('lang.why_handelbay.HandelBay_verifies_the_legitimacy_of_registered_companies_through_regulatory_entities') }}
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
          </div>
        </div>
      </div>
      <!-- .row -->
    </div>
  </section>
</template>
<script>

import global from "@/components/Global.vue"

export default {
  data(){
    return{
      global: global
    }
  }
}
</script>