<template>
  <!-- MODAL AGENDA TU DEMO AHORA -->
  <!-- Modal -->
  <div class="modal fade handelbay-modal" id="modal-demo" tabindex="-1" role="dialog" aria-labelledby="modal-demoLabel" style="">
    <div class="modal-dialog modal-md" role="document">
      <div class="modal-content">
        <div id="agendatudemo" style="">
          <div class="modal-header text-center">
            <h3 class="modal-title" style="color:black;font-family: inherit;" id="modal-demoLabel">
              {{ $t('lang.modal_your_demo.schedule_your_demo_now') }}
            </h3>
          </div>
          <div class="modal-body">
            <p class="title-body">
              {{ $t('lang.modal_your_demo.enter_your_business_email_and_receive_the_date_and_time_of_your_demo') }}
            </p>
            <div class="row">
              <div class="col-md-4 col-sm-4 col-xs-2">
                <div class="img-profile">
                  <img src="../assets/images/landing/handelbay/handelbay_agenda_profile.png" alt="">
                </div>
              </div>
              <div class="col-md-8 col-sm-8 col-xs-10">
                <form id="form_demo" class="form-valid">
                  <input type="hidden" id="token_rdstation" name="token_rdstation" value="04a3ede998e2464898b7b68c513486a3">
                  <input type="hidden" id="identificador" name="identificador" value="demo">
                  <div class="form-group">
                    <label for="name">
                      <span class="modal-title">
                        {{ $t('lang.modal_your_demo.full_company_name') }}
                      </span>
                      <input type="text" class="floating" name="name_demo" id="name_demo" minlength="3" required>
                    </label>
                  </div>
                  <div class="form-group">
                    <label class="" for="emailF"> 
                      <span>
                        {{ $t('lang.modal_your_demo.your_business_email') }}
                      </span>
                      <input type="email" class="floating" name="email_demo" id="email_demo" pattern="^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$" title="Correo inválido." required>
                    </label>
                  </div>
                  <div class="form-group">
                    <label class="" for="phone_demo" style="width: 85%">
                      <span> 
                        {{ $t('lang.modal_your_demo.contact_number') }}
                      </span>
                      <input type="phone" class="floating" name="phone_demo" id="phone_demo" title="Teléfono de contacto inválido." required>
                    </label>
                  </div>
                  <div class="form-group">
                    <label class="" for="profiles">
                      <span>
                        {{ $t('lang.modal_your_demo.select_the_profile') }}
                      </span>
                      <select type="select" class="floating" name="type_demo" id="type_demo" required>
                        <option value="comprador">
                          {{ $t('lang.modal_your_demo.buyer') }}
                        </option>
                        <option value="proveedor">
                          {{ $t('lang.modal_your_demo.supplier') }}
                        </option>
                        <option value="dual">
                          {{ $t('lang.modal_your_demo.dual') }}
                        </option>
                      </select>
                    </label>
                  </div>
                  <div class="form-group-check">
                    <p>
                      {{ $t('lang.modal_your_demo.by_registering_you_agree_to_our') }} 
                      <a href="<%=terminoscondiciones_path%>" style="text-decoration-line: inherit;" target="_blank">
                        <strong>
                          {{ $t('lang.modal_your_demo.terms_and_privacy_policy') }} 
                        </strong>
                      </a>
                    </p>
                  </div>
                  <hr>
                  <div class="success-demo">
                    <button data-dismiss="modal" class="btn btn-handelbay-success btn-transparent-success">
                      {{ $t('lang.modal_your_demo.to_close') }} 
                    </button>
                    <button type="submit" class="btn btn-handelbay-success" style="margin:0px 0px 15px 0;">
                      {{ $t('lang.modal_your_demo.schedule_your_demo') }}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <!-- end primera parte  -->
        </div>
        <!-- SEGUNDO MODAL DEL DEMO -->
        <div id="excelente" style="display:none;">
          <div class="modal-header text-center">
            <h3 class="modal-title" id="modal-demoLabel" style="color: black;font-family:inherit;">
              {{ $t('lang.modal_your_demo.excellent') }}
            </h3>
          </div>
          <div class="modal-body">
            <p class="title-body">
              {{ $t('lang.modal_your_demo.you_will_now_receive_an_email_with_all_the_necessary_details_for_the_HandelBay_DEMO') }} 
              {{ $t('lang.modal_your_demo.the_new_wave_of_business') }} 
            </p>
            <div class="row">
                <div class="col-md-4 col-sm-4 col-xs-2">
                    <div class="img-profile">
                        <img src="../assets/images/landing/handelbay/handelbay_agenda_profile.png" alt="">
                    </div>
                </div>
                <div class="col-md-8 col-sm-8 col-xs-10">
                    <div class="equipo text-center">
                        <p>
                          {{ $t('lang.modal_your_demo.our_team_will_be_happy_to_chat_with_you_and_tell_you_how_HandelBay_works') }}
                        </p>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-md-3">
                            <button data-dismiss="modal" class="btn btn-handelbay-success btn-transparent-success">
                              {{ $t('lang.modal_your_demo.to_close') }}
                            </button>
                        </div>
                        <div class="col-md-9">
                            <p style="margin-left: 20px;">
                              {{ $t('lang.modal_your_demo.if_you_do_not_receive_your_email_with_the_details') }} 
                              {{ $t('lang.modal_your_demo.go_back_to_the_previous_step_and_rectify_your_data') }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
        <!-- end segunda parte -->
      </div>
      <!-- .modal-content -->
    </div>
  </div>
  <!-- END MODAL AGENDA TU DEMO AHORA -->
</template>
<script>
export default {
  data(){
    return{
      showModalDemo: false
    }
  }
  
}
</script>