import Vue from "vue";
import App from "./App.vue";
import VueI18n from 'vue-i18n';
import es from './lang/es'
import en from './lang/en'
import pt from './lang/pt'

Vue.config.productionTip = false;

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: localStorage.getItem("lang") || navigator.language || navigator.browserLanguage,
  messages: {
    en: {
      lang: en
    },
    es: {
      lang: es
    },
    pt: {
      lang: pt
    }
  }
});
//estilos
import "./styles/header/header.scss"
import "./styles/landing.scss"
import "./styles/fonts.scss"
import "./styles/medias.scss"
import "./styles/mixin.scss"
import "./styles/styles.scss"
import "./styles/landing/animate.css"
import "./styles/landing/application.scss"
import "./styles/landing/font-awesome.min.css"
import "./styles/landing/landing.scss"
import "./styles/landing/style.scss"
import "./styles/application.scss"

import router from './router'

new Vue({
  router,
  render: (h) => h(App),
    i18n
}).$mount("#app");
