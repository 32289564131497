<template>
  <header class="header">
    <nav class="navbar navbar-default navbar-fixed-top">
      <div class="container-fluid handelbay-container-fluid">
        <!-- Brand and toggle get grouped for better mobile display -->
        <div class="navbar-header navbar-header-logo">
          <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-handelbay-tradicionalcollapse-1"
              aria-expanded="false">
              <span class="sr-only">Toggle navigation</span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
          </button>
          <a class="navbar-brand" href="/" style="display: none;">
            <img src="../assets/images/landing/handelbay/handelbay_logo_menu.png" alt="HandelBay">
          </a>
        </div>

        <!-- Collect the nav links, forms, and other content for toggling -->
        <div class="collapse navbar-collapse handelbay-navbar-collapse" id="bs-handelbay-tradicionalcollapse-1">
          <div class="container without-padding-left">
            <ul class="nav navbar-nav handelbay-navbar-left" @mouseover="hideT()">
              <li>
                <router-link class="logo-handelbay-img" to="/" @click.native="scrollUp">
                  <h1>
                    <img src="../assets/images/landing/handelbay/handelbay_logo_menu.png" alt="HandelBay">
                  </h1>
                </router-link>
              </li>
              <li class="header-list i" v-on:click="classActive('i')" @click="scrollUp">
                <router-link class="navbar-nav_link" to="/">{{ $t('lang.header.home') }}</router-link>
              </li>
              <li>
                <a class="navbar-nav_link">
                  {{ $t('lang.header.companies') }}
                  <span class="han-sr-only"></span>
                </a>
                <ul class="submenu">
                  <li class="submenu_item" @click="scrollUp">
                    <router-link to="/compradores">
                      {{ $t('lang.header.buyers') }}
                      <span class="han-sr-only"></span>
                    </router-link>
                  </li>
                  <li class="submenu_item" @click="scrollUp">
                    <router-link to="/proveedores">
                      {{ $t('lang.header.providers') }}
                      <span class="han-sr-only"></span>
                    </router-link>
                  </li>
                  <li class="submenu_item" @click="scrollUp">
                    <router-link to="/dual">
                      {{ $t('lang.header.dual') }}
                      <span class="han-sr-only"></span>
                    </router-link>
                  </li>
                </ul>
              </li>
              <li class="header-list t" v-on:click="classActive('t')" @click="scrollUp">
                <router-link class="navbar-nav_link" to="/tarifas">
                  {{ $t('lang.header.fare') }}
                  <span class="han-sr-only"></span>
                </router-link>
              </li>
              <li class="header-list p" v-on:click="classActive('p')" @click="scrollUp">
                <router-link class="navbar-nav_link" to="/porquehandelbay">
                  {{ $t('lang.header.why_handelbay') }}
                  <span class="han-sr-only"></span>
                </router-link>
              </li>
              <li class="header-list a" v-on:click="classActive('a')" @click="scrollUp">
                <router-link class="navbar-nav_link" to="/alianzas">
                  {{ $t('lang.header.alliances') }}
                  <span class="han-sr-only"></span>
                </router-link>
              </li>
              <li class="header-list b" v-on:click="classActive('b')" @click="scrollUp">
                <a class="navbar-nav_link" :href="url.blog">
                  {{ $t('lang.header.blog') }}
                  <span class="han-sr-only"></span>
                </a>
              </li>
            </ul>
            <div class="nav navbar-nav handelbay-navbar-left">
              <select v-model="landing" class="form-control solutions" @change="goLanding(landing)">
                <option value="" disabled selected>Soluciones</option>
                <option v-for="item in landings" :value="item.value" :key="item.id">
                  {{ item.name }}
                </option>
              </select>
            </div>
            <ul class="nav navbar-nav navbar-right handelbay-navbar-right">
              <div class="img-international" @mouseover="showT()" >
                <img src="../assets/Colombia.png">
                <div style="position:relative;" @mouseover="showT()" @mouseleave="hideT()">
                  <div class="tooltip-country d-none">
                    {{ $t('lang.header.you_are_in_HandelBay') }} {{url.name_country}}
                    <a href="https://handelbay.com/">{{ $t('lang.header.change_country_here') }}</a>
                  </div>
                </div>
              </div>
              <li class="active navbar-right_item" @mouseover="hideT()">
                <a :href="url.sign_up">
                  {{ $t('lang.header.sign_up') }}
                </a>
              </li>
              <li class="navbar-right_item">
                <a :href="url.sign_in">
                  {{ $t('lang.header.sign_in') }}
                </a>
              </li>
              <li>
              <!-- <a href="/buscar" class="search-icon-landing"> -->
              <a :href="url.search" class="search-icon-landing">
                <i class="icon dripicons-search"></i>
              </a>
              </li>
            </ul>
          </div>
        </div>
        <!-- /.navbar-collapse -->

        <!-- <a href="/buscar" class="search-icon-landing"> -->
        <a :href="url.search" class="search-icon-landing">
          <i class="icon dripicons-search"></i>
        </a>
      </div>
      <!-- /.container-fluid -->
      </nav>
    <div class="lang" @mouseover="langs = true" @mouseleave="langs = false">
      <div class="select-lang" v-if="langs == true">
        <div class="d-flex">
          <img src="../assets/lang/español.png" height="70" width="70" class="" v-on:click="changeLang('es')" >
          <p>ES</p>
        </div>
        <div class="d-flex">
          <img src="../assets/lang/ingles.png" height="70" width="70" class="" v-on:click="changeLang('en')">
          <p>EN</p>
        </div>
        <div class="d-flex">
          <img src="../assets/lang/portugues.png" height="70" width="70" class="" v-on:click="changeLang('pt')">
          <p>PT</p>
        </div>
      </div>
      <a class="img-international" target="_blank">
        <img src="../assets/lang/español.png" height="70" width="70" class="" v-if="langActive('es')" v-on:click="changeLang('es')" >
        <img src="../assets/lang/ingles.png" height="70" width="70" class="" v-if="langActive('en')" v-on:click="changeLang('en')">
        <img src="../assets/lang/portugues.png" height="70" width="70" class="" v-if="langActive('pt')" v-on:click="changeLang('pt')">
      </a>
    </div>
  </header>
</template>

<script>

import global from '@/components/Global.vue'

export default {
  methods:{
    classActive: (clas) => {
      $('li.header-list.active').removeClass('active');
      $("."+clas).addClass('active');
      $("body").focus();
    },
    scrollUp: () => {
      window.scroll({top:0})
    },
    hideT: () => {
      $(".tooltip-country").addClass("d-none")
    },
    showT: () => {
      $(".tooltip-country").removeClass("d-none")
    },
    goLanding: (landing) => {
      var win = window.open(landing, '_blank');
      win.focus();
    },
    langActive: (lang) => {
      let l = localStorage.getItem("lang") || navigator.browserLanguage
      l = l || navigator.language
      return (l.indexOf(lang) == 0) ? true : false
    },
    changeLang: (lang) => {
      localStorage.setItem("lang", lang);
      window.location.reload();
    }
  },
  data(){
    return{
      url: global,
      langs: false,
      landings: [
      {id: 1 ,name: 'Licitaciones privadas', value: "https://lp.handelbay.com/comprador-licitaciones-privadas"},
      {id: 2 ,name: 'Portal de proveedores', value: "https://lp.handelbay.com/portal-de-proveedores"},
      {id: 3 ,name: 'Requisiciones internas', value: "https://lp.handelbay.com/comprador-requisiciones-internas"},
      {id: 4 ,name: 'Gestor de cotizaciones', value: "https://lp.handelbay.com/gestor-de-cotizaciones"},
      {id: 5 , name: 'Evaluación de proveedores', value: "https://lp.handelbay.com/evaluación-de-proveedores"}
    ],
    landing:""
    }
  }
}
 
</script>
<style lang="scss">
.navbar-default .navbar-nav>.active>a, .navbar-default .navbar-nav>.active>a:focus, .navbar-default .navbar-nav>.active>a:hover {
  color: #f4f8fb;
  background-color: #e7e7e7;
}
.img-international{
  margin-right: 20px;
  img{
    width: 30px;
    height: 30px;
    border-radius: 25px;
  }
  .tooltip-country{
    position:absolute;
    width: 240px;
    left: -110px;
    top: 5px;
    background: #e7e7e7;
    border-radius: 10px;
    padding: 5px;
    font-size: 14px;
    a{
      font-size: 13px;
      text-decoration: underline;
    }
  }
}
.solutions{
  width: 100px;
}
</style>
