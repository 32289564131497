<template>
  <div class="home">
    <!-- Mi compañia ahora optimiza -->
    <!-- <% @title="HandelBay: plataforma digital de comercio empresarial"%> -->
    <section id="handelbay_optimizes" class="section-top-contend">
      <div class="handelbay_optimizes-content">
        <div class="container">
          <div class="row">
            <div class="col-md-6 col-sm-8 col-md-offset-1 col-xs-9">
              <div class="title" itemscope :itemtype="url">
                <h2 class="title-1" itemprop="HandelBay proveedores">
                  {{ $t('lang.home.do_you_search') }}
                  <strong>
                    {{ $t('lang.home.optimize') }}
                  </strong> 
                  {{ $t('lang.home.your_purchasing_management') }}
                </h2>
                <div>
                  <a :href="url.lp" class="btn no-margin-left btn-handelbay-success btn-handelbay-black">
                    {{ $t('lang.home.i_want_to_know_how') }}
                  </a>
                </div>
                <h3 class="title-2" itemprop="HandelBay clientes">
                  <br>
                  {{ $t('lang.home.do_you_want_to_expand_your') }}
                   <strong>
                    {{ $t('lang.home.opportunities') }}
                   </strong> 
                   {{ $t('lang.home.of_sales') }} 
                </h3>
              </div>
              <div class="title-footer">
                <router-link class="btn no-margin-left btn-handelbay-success provider-link btn-handelbay-black" to="proveedores">
                  {{ $t('lang.home.i_want_to_sell_more') }}
                </router-link>
              </div>
            </div>
          </div>
          <!-- .row -->
        </div>
      </div>
        <!-- .container -->
    </section>
    <!-- End mi compañia ahora optimiza -->

    <!-- SUMERGETE EN LA NUEVA OLA DE NEGOCIOS -->
    <section id="immerse">
      <div class="immerse_background hidden-xs">
        <img src="../assets/images/landing/handelbay/handelbay_sumergete.png" alt="Sumergete en la nueva ola">
      </div>
      <div class="container">
        <div class="row">
          <div class="col-md-5 col-sm-6 col-md-offset-1 text-left">
            <div class="title" itemscope itemtype="https://handelbay.com/">
              <h2 class="title-2" itemprop="HandelBay sumérgete">
                {{ $t('lang.home.dive_into_the_new') }}
                <br> 
                {{ $t('lang.home.wave_of_business') }}
              </h2>
              <span class="handelbay-active"></span>
            </div>
            <div class="handelbay-text" itemscope itemtype="https://handelbay.com/">
              <p itemprop="HandelBay es un murketplace">
                {{ $t('lang.home.HandelBay_is_a_B2B_marketplace') }}
                <br>
                {{ $t('lang.home.to_business_purchases_and_sales') }}
              </p>
            </div>
          </div>
        </div>
          <!-- .row -->
          <div class="row">
            <div class="col-md-10 col-sm-12 col-md-offset-1">
              <div class="handelbay-allows-you text-center">
                <div class="title-footer">
                  <h3 style="font-size:23px;">
                    <strong>
                      {{ $t('lang.home.HandelBay') }}
                    </strong> 
                    {{ $t('lang.home.allows_you_to_manage_two_of_the_processes') }}
                    <br> 
                    {{ $t('lang.home.most_important_to_your_business:') }}
                    <strong>
                      {{ $t('lang.home.catering') }} 
                    </strong>
                    {{ $t('lang.home.and') }} 
                    <strong>
                      {{ $t('lang.home.sales') }}
                    </strong>.
                  </h3>
                  <a :href="url.lp" class="btn btn-handelbay-success btn-handelbay-black">
                    {{ $t('lang.home.schedule_your_demo_now') }}
                  </a>
                </div>
              </div>
              <!-- .handelbay-allows-you -->
            </div>
          </div>
          <!-- .row -->
      </div>
    </section>
    <!-- END SUMERGETE EN LA NUEVA OLA DE NEGOCIOS -->
    <ModalTuDemo v-show="showModalDemo" @close="showModalDemo=false"/>
    <!-- .handelbay-background-curva -->
    <section id="handelbay-background-curva">

    </section>
    <!-- .handelbay-background-curva -->

    <!-- CONOCE LOS BENEFICIOS QUE TENEMOS PARA TI -->
    <section id="benefits">
      <div class="container">
        <div class="row">
          <div class="col-md-10 col-sm-12 col-md-offset-1 text-left">
            <div class="title" itemscope itemtype="https://handelbay.com/">
              <h2 class="title-2" itemprop="HandelBay beneficios">
                {{ $t('lang.home.know_the_benefits') }}
                <br> 
                {{ $t('lang.home.what_do_we_have_for_you') }}
              </h2>
              <span class="handelbay-active"></span>
            </div>
            <Benefit />
            <!-- .row -->
          </div>
        </div>
        <!-- .row -->
      </div>
    </section>
    <!-- END CONOCE LOS BENEFICIOS QUE TENEMOS PARA TI -->

    <!-- VINCULA TU EMPRESA AHORA -->
    <section id="link-company">
      <div class="container">
        <div class="row">
          <div class="col-md-10 col-sm-12 col-md-offset-1 text-left">
            <div class="title" itemscope :itemtype="url">
              <h2 class="title-2" itemprop="HandelBay beneficios">
                {{ $t('lang.home.bind') }}
                <br> 
                {{ $t('lang.home.your_company_now') }}
              </h2>
              <span class="handelbay-active"></span>
            </div>
          </div>
        </div>
        <!-- .row -->
      </div>
      <div class="img-content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-6 images-left">
              <img src="../assets/images/landing/handelbay/handelbay_vinculaempresa1.png" alt="Vincula tu empresa ahora">
            </div>
            <div class="col-md-6 images-right">
              <img src="../assets/images/landing/handelbay/handelbay_vinculaempresa2.png" alt="Vincula tu empresa ahora">
            </div>
          </div>
        </div>
        <div class="container">
          <div class="title-footer text-center">
            <a :href="url.sign_up" class="btn btn-handelbay-success btn-handelbay-black">
              {{ $t('lang.home.sign_up_free') }}
            </a>
          </div>
        </div>
      </div>
    </section>
    <!-- END VINCULA TU EMPRESA AHORA -->

    <!-- LA MEJOR FORMA DE OPTIMIZAR TU TIEMPO -->
    <section id="optimize-time-video" style="display: none;">
      <div class="embed-responsive_ embed-optimize">
        <iframe src="https://www.youtube.com/embed/If5O5Q0zX_Y?rel=0&autoplay=0" height="100%" width="100%"></iframe>
        <a href="javascript:void(0)" class="btn btn-handelbay-success btn-handelbay-write" v-on:click="showEmbed('1')">
          <i class="fa fa-times-circle"></i>
          <span>
            {{ $t('lang.home.ocultar_video') }}
          </span>
        </a>
      </div>
    </section>
    <section id="optimize-time">
      <div class="optimize-time_content">
        <div class="container">
          <div class="row">
            <div class="col-md-6 col-md-offset-6 text-left">
              <h2 class="title-video">
                {{ $t('lang.home.dive_into_the_business_wave') }}
              </h2>
              <p>
                {{ $t('lang.home.HandelBay_is_the_business_social_network_that_helps_you_reduce_operating_times_and_optimize_your_purchases') }}
              </p>
              <p>
                {{ $t('lang.home.we_invite_you_to_watch_the_following_video_so_you_can_understand_why_your_company_should_be_on_HandelBay') }}
              </p>
              <br>
              <a href="javascript:void(0)" class="btn no-margin-left btn-handelbay-success btn-handelbay-write" v-on:click="showEmbed('0')">
                <i class="fa fa-play-circle"></i>
                <span>
                  {{ $t('lang.home.watch_video') }}
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- END LA MEJOR FORMA DE OPTIMIZAR TU TIEMPO -->

    <!-- NO ESPERES MAS TIEMPO -->
    <section id="do-no-wait-for-time">
      <div class="container">
        <div class="row">
          <div class="col-md-4 col-sm-5 col-md-offset-1 text-left">
            <div class="title" itemscope :itemtype="url.url">
              <h2 class="title-2" itemprop="HandelBay en tu día a día">
                {{ $t('lang.home.dont_wait') }}
                <br> 
                {{ $t('lang.home.more_time') }}
              </h2>
              <span class="handelbay-active"></span>
            </div>
            <div class="handelbay-text" itemscope :itemtype="url.url">
              <p itemprop="HandelBay es la solución">
                {{ $t('lang.home.start_enjoying_the_benefits_of_the_new_business_marketplace') }}
              </p>
            </div>
          </div>
          <div class="col-md-6 col-sm-6 text-left">
            <div class="row items">
              <div class="col-md-6 col-sm-12">
                <div class="more">
                    ✔
                </div>
                <span>
                  {{ $t('lang.home.online_shopping_24_7') }}
                </span>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="more">
                    ✔
                </div>
                <span>
                  {{ $t('lang.home.transparency') }}
                </span>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="more">
                    ✔
                </div>
                <span>
                  {{ $t('lang.home.verified_network') }}
                </span>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="more">
                    ✔
                </div>
                <span>
                  {{ $t('lang.home.more_sales') }}
                </span>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="more">
                    ✔
                </div>
                <span>
                  {{ $t('lang.home.savings') }}
                </span>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="more">
                    ✔
                </div>
                <span>
                  {{ $t('lang.home.best_providers') }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <!-- .row -->
        <div class="row">
          <div class="col-md-12">
            <div class="title-footer text-center">
              <a href="https://lp.handelbay.com/quiero-un-demo-interactivo" class="btn btn-handelbay-success" target="_blank">
                {{ $t('lang.home.schedule_your_demo') }}
              </a>
              <a :href="url.sign_up" class="btn btn-handelbay-success btn-handelbay-black">
                {{ $t('lang.home.sign_up_free') }}
              </a>
            </div>
          </div>
        </div>
      </div>
        <!-- .container -->
    </section>
    <!-- END NO ESPERES MAS TIEMPO -->

    <!-- .handelbay-background-curva -->
    <section id="handelbay-background-curva-bottom">

    </section>
    <!-- .handelbay-background-curva -->

    <!-- END BECAUSE HANDELBAY -->
    <PorqueHandelbay />
    <!-- END BECAUSE HANDELBAY -->

    <!--   CON RESPALDO DE -->
    <section id="with-support-from">
      <div class="container text-center">
        <div class="row btn-content-handelbay">
          <div class="col-md-12 text-center" itemscope>
            <h3 itemprop="handelbay">
              {{ $t('lang.home.learn_why_you_should_be_part_of_HandelBay') }}
            </h3>
            <router-link to="/porquehandelbay" class="btn btn-handelbay-success" @click.native="scrollUp">
              {{ $t('lang.home.click_here') }}
            </router-link>
          </div>
        </div>
        <div class="indicators_wrap">
          <Indicator />
        </div>
        <div class="content-text">
          <div class="text d-none">
              <h2>
                {{ $t('lang.home.some_of_our_customers') }}
              </h2>
          </div>
          <div class="respaldo_logos d-none">
            <!-- <%= render partial:"landing/home/partials/carousel" %> -->
          </div>
          <div class="btn--handelbay">
            <a href="#" data-toggle="modal" data-target="#modal-pdf" class="btn btn-handelbay-success btn-handelbay-black">
              {{ $t('lang.home.receive_all_the_detailed_information_of_HandelBay_in_PDF') }}
            </a>
          </div>
        </div>
      </div>
      <a style="background: none; position: fixed; right:50px; bottom: 50px; z-index:9999" href="https://api.whatsapp.com/send/?phone=573147711318&text=Hola,+requiero+ayuda+en+la+plataforma+HandelBay&app_absent=0" target="_blank">
        <img src="https://handelbay-socialmedia.s3.us-east-2.amazonaws.com/Whatsapp.png" height="65" width="65">
      </a>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import global from '@/components/Global.vue'
import ModalTuDemo from '@/components/ModalTuDemo.vue'
import Benefit from "@/components/Benefits.vue"
import Indicator from "@/components/Indicator.vue"
import PorqueHandelbay from "@/components/PorqueHandelbay.vue"

export default {
  name: 'Home',
  components: {
    ModalTuDemo,
    Benefit,
    Indicator,
    PorqueHandelbay
  },
  data(){
    return{
      url: global,
      showModalDemo: false
    }
  },
  methods:{
    scrollUp: () => {
      window.scroll({top: 0})
    },
    showEmbed: (x) =>{
      if (x == '0') {
        $("#optimize-time-video").show();
        $("#optimize-time").hide();
        $(".embed-optimize iframe").attr(
            "src",
            "https://www.youtube.com/embed/If5O5Q0zX_Y?rel=0&autoplay=1"
        );
      } else {
          $("#optimize-time-video").hide();
          $("#optimize-time").show();
          $(".embed-optimize iframe").attr(
              "src",
              "https://www.youtube.com/embed/If5O5Q0zX_Y?rel=0&autoplay=0"
          );
      }
    }
  }
  
}
</script>
