<template>
  <!-- FOOTER -->
  <footer id="subscribe">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="handelbay-form-subscribe">
            <div class="title text-center" itemscope>
              <h2 class="text-left title_footer" itemprop="noticias">
                {{ $t('lang.footer.receive_news_and_learn_more') }}
                <br> {{ $t('lang.footer.of_the_new_business_network') }}
              </h2>
            </div>
            <div class="text-center">
              <form id="form_handelbay" class="form-inline ng-pristine ng-valid" action="/blog" accept-charset="UTF-8" method="post">
              <input name="utf8" type="hidden" value="✓">
              <div class="row">
                <input type="hidden" id="token_rdstation" name="token_rdstation" value="04a3ede998e2464898b7b68c513486a3">
                <input type="hidden" id="identificador" name="identificador" value="blog">
                <div class="col-md-6 col-sm-6 col-xs-6 col-xs-offset-1 col-sm-offset-1 col-md-offset-2 handelbay-form">
                  <input type="email" name="email" id="email" placeholder="Ingresa tu correo empresarial" class="" style="font-size: 16px !important" required="required">
                  <input type="hidden" name="type" id="type" value="0">
                </div>
                <div class="col-md-2 col-sm-2 col-xs-5 text-left actionSubmit">
                  <input type="submit" name="commit" value="Suscribirme" class="boton g-recaptcha" data-sitekey="6LcMVv0ZAAAAADKTESsXwPsi1ebP7JWY1u9h1xol" data-callback="onSubmit" data-action="submit" data-disable-with="Suscribirme">
                </div>
              </div>
              </form>
            </div>
          </div>
          <!-- .handelbay-form-subscribe -->
        </div>
      </div>
      <!-- .row -->
      <div class="row">
        <div class="col-md-4 col-sm-4 handelbay-img-footer text-center">
          <a :href="global.url">
            <img src="../assets/images/landing/handelbay/handelbay_logo_footer.png" alt="Red social empresarial para compradores potenciales y proveedores competitivos">
          </a>
        </div>
        <div class="col-md-7 col-sm-7">
          <div class="row">
            <div class="col-md-2 col-sm-4 col-xs-4">
              <ul class="handelbay-HandelBay">
                <li class="active" @click="scrollUp">
                  <router-link to="/">
                    {{ $t('lang.footer.Handelbay') }}
                  </router-link>
                </li>
                <li class="" @click="scrollUp">
                  <router-link to="/">
                    {{ $t('lang.footer.what_is') }}
                  </router-link>
                </li>
                <li class="" @click="scrollUp">
                  <router-link to="/compradores">
                    {{ $t('lang.footer.buyer') }}
                  </router-link>
                </li>
                <li class="" @click="scrollUp">
                  <router-link to="/proveedores">
                    {{ $t('lang.footer.provider') }}
                  </router-link>
                </li>
                <li class="" @click="scrollUp">
                  <router-link to="/dual">
                    {{ $t('lang.footer.dual') }}
                  </router-link>
                </li>
                <li class="" @click="scrollUp">
                  <router-link to="Tarifas">
                    {{ $t('lang.footer.tariff') }}
                  </router-link>
                </li>
                <li class="" @click="scrollUp">
                  <a :href="global.search">
                    {{ $t('lang.footer.searcher') }}
                  </a>
                </li>
              </ul>
            </div>
            <div class="col-md-2 col-sm-4 col-xs-4">
              <ul class="handelbay-HandelBay">
                <li class="active" @click="scrollUp">
                  <router-link to="/alianzas">
                    {{ $t('lang.footer.alliances') }}
                  </router-link>
                </li>
                <li class="" @click="scrollUp">
                  <router-link to="alianzas">
                    {{ $t('lang.footer.acopc') }}
                  </router-link>
                </li>
              </ul>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-4">
              <ul class="handelbay-HandelBay">
                <li class="active" @click="scrollUp">
                  <router-link to="/acerca">
                    {{ $t('lang.footer.company') }}
                  </router-link>
                </li>
                <li class="" @click="scrollUp">
                  <router-link to="/acerca">
                    {{ $t('lang.footer.about') }}
                  </router-link>
                </li>
                <li class="" @click="scrollUp">
                </li>
                <li class="" @click="scrollUp">
                  <router-link to="/terminosycondiciones">
                    {{ $t('lang.footer.legal') }}
                  </router-link>
                </li>
                <li class="" @click="scrollUp">
                  <router-link to="/porquehandelbay">
                    {{ $t('lang.footer.why_HB?') }}
                  </router-link>
                </li>
              </ul>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-4">
              <ul class="handelbay-HandelBay">
                <li class="active" @click="scrollUp">
                  <a :href="global.blog">
                    {{ $t('lang.footer.blog') }}
                  </a>
                </li>
                <li class="" @click="scrollUp">
                  <a :href="global.blog + '?category=6'">
                    {{ $t('lang.footer.small_companies') }}
                  </a>
                </li>
                <li class="" @click="scrollUp">
                  <a :href="global.blog + '?category=7'">
                    {{ $t('lang.footer.big_enterprises') }}
                  </a>
                </li>
                <li class="" @click="scrollUp">
                  <a :href="global.blog + '?category=8'">
                    {{ $t('lang.footer.shopping_of_the_future') }}
                  </a>
                </li>
              </ul>
            </div>
            <div class="col-md-2 col-sm-4 col-xs-4">
              <ul class="handelbay-HandelBay">
                <li class="active" @click="scrollUp">
                  <a href="#">
                    {{ $t('lang.footer.support') }}
                  </a>
                </li>
                <li class="" @click="scrollUp">
                  <a :href="global.soporte" target="_blank">Soporte</a>
                </li>
                <li class="" @click="scrollUp">
                  <a :href="global.sign_in">
                    {{ $t('lang.footer.sign_in') }}
                  </a>
                </li>
                <li class="" @click="scrollUp">
                  <a :href="global.sign_up">
                    {{ $t('lang.footer.sign_up') }}
                  </a>
                </li>
                <li class="" @click="scrollUp">
                  <a :href="global.soporte" target="_blank">
                    {{ $t('lang.footer.faq') }}
                  </a>
                </li>
                  <li class="" @click="scrollUp">
                  <a :href="global.was_cellphone" target="_blank">
                    <img src="https://handelbay-socialmedia.s3.us-east-2.amazonaws.com/Whatsapp.png" height="50" width="50">
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div> 
      </div>
      <!-- .row -->
      <div class="row">
        <div class="handelbay-social-network text-center">
          <a :href="global.facebook" target="_blank">
            <i class="fa fa-facebook" aria-hidden="true"></i>
          </a>
          <a :href="global.instagram" target="_blank">
            <i class="fa fa-instagram" aria-hidden="true"></i>
          </a>
          <a :href="global.youtube" target="_blank">
            <i class="fa fa-youtube-play" aria-hidden="true"></i>
          </a>
          <a :href="global.linkedin" target="_blank">
            <i class="fa fa-linkedin" aria-hidden="true"></i>
          </a>
        </div>
        <div class="col-md-12 text-center handelbay-ignite">
          <p>
            {{ $t('lang.footer.Handelbay') }} © 
            {{ new Date().getFullYear() }} 
            {{ $t('lang.footer.all_rights_reserved') }} 
            <a href="http://www.ignite.com.co" target="_blank">
              {{ $t('lang.footer.ignite_technologies') }}
            </a>
          </p>
        </div>
      </div>
      <!-- .row -->
    </div>
  </footer>
</template>
<script>

import global from '@/components/Global.vue'

export default {
  data(){
    return{
      global: global
    }
  },
  methods:{
    scrollUp: () =>{
      window.scroll({top:0})
    }
  }
}
 
</script>